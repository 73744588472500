import React from "react";
import ReactDOMClient from "react-dom/client";
import { Mobile } from "./screens/Mobile";
import { Desktop } from "./screens/Desktop";

const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);

let width = window.innerWidth;

root.render(width > 768 ? <Desktop/> : <Mobile />);
