import React from "react";
import "./style.css";

export const Desktop = () => {
  return (
    <div className="desktop">
      <div className="div">
        <div className="group">
          <div className="frame">
            <div className="div-wrapper">
              <div className="text-wrapper">Home</div>
            </div>
            <div className="div-wrapper">
              <div className="text-wrapper">About Us</div>
            </div>
          </div>
          <img className="bzinga-final-logo" alt="Bzinga final logo" src="/img/bzinga-final-logo-01-1.png" />
          <div className="app-icons">
            <div className="image-wrapper">
              <img className="image" alt="Image" src="/img/image-2.png" />
            </div>
            <div className="image-wrapper">
              <img className="img" alt="Image" src="/img/image-3.png" />
            </div>
          </div>
          <div className="get-bzinga-app-on-wrapper">
            <div className="get-bzinga-app-on">
              <span className="span">Get </span>
              <span className="text-wrapper-2">Bzinga</span>
              <span className="span"> App on</span>
            </div>
          </div>
        </div>
        <div className="overlap">
          <p className="follow-easy-steps">
            <span className="text-wrapper-3">Follow </span>
            <span className="text-wrapper-4">4 easy steps</span>
            <span className="text-wrapper-3"> to Bzinga</span>
          </p>
          <div className="group-2">
            <img className="layer" alt="Layer" src="/img/layer-1-1.png" />
            <div className="overlap-group">
              <div className="frame-wrapper">
                <div className="frame-2">
                  <div className="text" />
                </div>
              </div>
              <div className="img-wrapper">
                <img className="image-2" alt="Image" src="/img/image-263.png" />
              </div>
              <div className="overlap-group-wrapper">
                <div className="rectangle-wrapper">
                  <div className="rectangle" />
                </div>
              </div>
            </div>
            <div className="frame-3">
              <img className="image-3" alt="Image" src="/img/image-266.png" />
            </div>
            <div className="frame-4">
              <img className="image-3" alt="Image" src="/img/image-265.png" />
            </div>
            <p className="get-the-app-login">Get the app &amp; login everyday for rewards</p>
            <p className="play-for-your">Play for your favorite products in games &amp; auctions</p>
            <p className="p">Win the products or runner up rewards</p>
            <div className="text-wrapper-5">Enjoy doorstep delivery</div>
            <img className="layer-2" alt="Layer" src="/img/layer-2-1.png" />
            <img className="layer-3" alt="Layer" src="/img/layer-2-2.png" />
            <img className="layer-4" alt="Layer" src="/img/layer-3-1.png" />
          </div>
        </div>
        <h1 className="get-the-app-and-win">
          <span className="text-wrapper-3">Get the app and </span>
          <span className="text-wrapper-4">win</span>
        </h1>
        <div className="text-2" />
        <div className="overlap-2">
          <p className="get-the-app-and-win-2">
            <span className="text-wrapper-3">Get the app and </span>
            <span className="text-wrapper-4">win</span>
          </p>
          <div className="group-3" />
          <p className="win-products-from">
            <span className="text-wrapper-6">Win</span>
            <span className="text-wrapper-4">&nbsp;&nbsp;Products from top Brands</span>
          </p>
          <div className="frame-5">
            <div className="frame-6">
              <img className="image-4" alt="Image" src="/img/image-267.png" />
            </div>
            <div className="frame-7">
              <img className="image-5" alt="Image" src="/img/image-268.png" />
            </div>
            <div className="frame-8">
              <div className="overlap-group-2">
                <img className="image-6" alt="Image" src="/img/image-269.png" />
                <div className="rectangle-2" />
              </div>
            </div>
          </div>
        </div>
        <p className="scan-code-to">SCAN CODE TO DOWNLOAD BZINGA APP</p>
        <div className="overlap-wrapper">
          <div className="overlap-3">
            <div className="group-4">
              <div className="overlap-group-3">
                <img className="image-7" alt="Image" src="/img/image-5.png" />
                <img className="image-8" alt="Image" src="/img/image-4-1.png" />
                <div className="rectangle-3" />
                <div className="text-wrapper-7">GET</div>
              </div>
            </div>
            <div className="frame-9">
              <div className="overlap-4">
                <img
                  className="microsoftteams-image"
                  alt="Microsoftteams image"
                  src="/img/microsoftteams-image-35.png"
                />
                <img
                  className="microsoftteams-image-2"
                  alt="Microsoftteams image"
                  src="/img/microsoftteams-image-34.png"
                />
                <div className="group-5" />
              </div>
            </div>
          </div>
        </div>
        <div className="frame-10">
          <img className="image-9" alt="Image" src="/img/image-7.png" />
          <img className="image-10" alt="Image" src="/img/image-6.png" />
        </div>
        <div className="frame-11">
          <p className="text-wrapper-8">An Android and iOS application</p>
          <p className="text-wrapper-9">3500+ products on the app</p>
          <div className="text-wrapper-9">100% authentic products</div>
        </div>
        <img className="bzinga-app-QR" alt="Bzinga app QR" src="/img/bzinga-app-qr-1.svg" />
        <div className="overlap-5">
          <div className="frame-12">
            <div className="ellipse" />
            <div className="ellipse-2" />
            <div className="ellipse-2" />
          </div>
          <img className="play-auction-and" alt="Play auction and" src="/img/play-auction-and-games-1st-banner-1.png" />
        </div>
        <div className="frame-13">
          <div className="group-6">
            <img className="image-11" alt="Image" src="/img/image-258.png" />
          </div>
          <div className="group-6">
            <img className="image-12" alt="Image" src="/img/image-261.png" />
          </div>
          <div className="group-6">
            <img className="image-13" alt="Image" src="/img/image-260.png" />
          </div>
          <div className="group-6">
            <img className="image-14" alt="Image" src="/img/image-259.png" />
          </div>
        </div>
        <div className="group-7">
          <div className="p-wrapper">
            <p className="text-wrapper-10">© 2023 Bzinga Technology. All rights reserved. | Privacy Policy</p>
          </div>
        </div>
        <div className="ellipse-3" />
        <div className="ellipse-4" />
        <div className="ellipse-5" />
      </div>
    </div>
  );
};
